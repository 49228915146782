import React from 'react'
import {IntlProvider} from 'react-intl'
import {useSelector} from "react-redux"

import {userLocale} from "../store/user/selectors"
import en from './en'
import vi from './vi'

export default props => {
  const langs = {en, vi}
  const locale = useSelector(userLocale)

  return (
    <IntlProvider messages={langs[locale]} locale={locale}>
      {props.children}
    </IntlProvider>
  )
}
