import {REQUEST_PAGE_SIZE, REQUEST_BIG_PAGE_SIZE} from '@utils/constants'

export const buildRequestParamRest = ({path, suffix = '', useBigPageSize = false}) => {
  const mappers = [
    ['list', 'GET'],
    ['store', 'POST'],
    ['update', 'PUT'],
    ['detail', 'GET'],
    ['destroy', 'DELETE'],
  ].map(arr => {
    const key = `${arr[0]}${suffix}`
    const value = {
      path,
      method: arr[1],
    }

    switch (arr[0]) {
      case 'list':
        value.data = {page: 1, limit: useBigPageSize ? REQUEST_BIG_PAGE_SIZE : REQUEST_PAGE_SIZE, hasField: true}
        break
      case 'detail':
        value.data = {hasField: true, query: -1}
        break
      case 'update':
      case 'destroy':
        value.data = {query: -1}
    }

    return [key, value]
  })

  return Object.fromEntries(mappers)
}
