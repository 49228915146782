import {REQUEST_PAGE_SIZE, REQUEST_BIG_PAGE_SIZE} from '@utils/constants'
import {buildRequestParamRest} from './helpers'

export default {
  url: `${process.env.REACT_APP_API_URL}/erp/`,
  initGct: {
    path: 'giay-cong-tac-init',
    method: 'GET',
    data: {
      query: -1,
    }
  },
  sumPrice: {
    path: 'giay-cong-tac-price',
    method: 'GET',
    data: {
      query: -1,
    }
  },
  ...buildRequestParamRest({path: 'giay-cong-tac', suffix: 'Gct'}),
  ...buildRequestParamRest({path: 'gct-chi-tiet', suffix: 'GctChiTiet'}),
  ...buildRequestParamRest({path: 'gct-assignee', suffix: 'GctAssignee'}),
  ...buildRequestParamRest({path: 'gct-chi-phi', suffix: 'GctChiPhi'}),
  lichHoatDong: {
    path: 'gct-assignee-lich-hoat-dong',
    method: 'GET',
    data: {
      started_at: -1,
    }
  },
  assigneesInfo: {
    path: 'gct-assignee',
    method: 'GET',
    data: {
      query: -1,
      isShort: true,
    }
  },
  changeAssigner: {
    path: 'giay-cong-tac-change-assigner',
    method: 'PUT',
    data: {
      query: -1,
    }
  },
  statistic: {
    path: 'giay-cong-tac-statistic',
    method: 'GET',
  },
  updateJsonVal: {
    path: 'giay-cong-tac-update-meta',
    method: 'PUT',
    data: {
      query: -1,
      key: null,
    }
  },
  makeStatusForAllHm: {
    path: 'giay-cong-tac-update-all-status',
    method: 'PUT',
    data: {
      query: -1,
      status: null,
    },
  },
  ...buildRequestParamRest({path: 'gct-lich-gv', suffix: 'GctLichGv', useBigPageSize: true}),
  ...buildRequestParamRest({path: 'gct-lich-gv-v1', suffix: 'GctLichGvV1', useBigPageSize: true}),
  detailForLichGv: {
    path: 'giay-cong-tac-lich-giang-vien',
    method: 'GET',
    data: {
      so_gct: -1
    },
  },
  listLichGvTongHop: {
    path: 'gct-lich-gv',
    method: 'GET',
    data: {
      page: 1,
      limit: REQUEST_PAGE_SIZE,
      hasField: true,
    },
  },
  listLichGvTongHopV1: {
    path: 'gct-lich-gv-v1',
    method: 'GET',
    data: {
      page: 1,
      limit: REQUEST_PAGE_SIZE,
      hasField: true,
    },
  },
  listLichGvThangV1: {
    path: 'gct-lich-gv-v1',
    method: 'GET',
    data: {
      page: 1,
      limit: REQUEST_BIG_PAGE_SIZE,
      hasField: true,
      hasTotal: true,
    },
  },
  changeFinalDate: {
    path: 'giay-cong-tac-change-final-date',
    method: 'PUT',
    data: {
      query: -1,
      final_date: null,
    }
  }}
