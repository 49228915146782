import {buildRequestParamRest} from './helpers'

export default {
  url: `${process.env.REACT_APP_API_URL}/erp/`,
  ...buildRequestParamRest({path: 'khach-hang'}),
  ...buildRequestParamRest({path: 'khach-hang-so-huu', suffix: 'Khsh'}),
  searchKhsh: {
    path: 'khach-hang-so-huu-list',
    method: 'GET',
    data: {
      page: 1,
      limit: 2,
      hasField: false,
    }
  }
}
