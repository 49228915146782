import { REQUEST_BIG_PAGE_SIZE, REQUEST_PAGE_SIZE } from '@utils/constants'
import { buildRequestParamRest } from './helpers'

export default {
  url: `${process.env.REACT_APP_API_URL}/erp/`,
  ...buildRequestParamRest({path: 'nhan-vien', suffix: ''}),
  maxCode: {
    path: 'nhan-vien-max-code',
    method: 'GET',
  },
  options: {
    path: 'nhan-vien-options',
    method: 'GET',
  },
  listSalary: {
    path: 'nhan-vien-salary',
    method: 'GET',
    data: {query: -1, page: 1, limit: REQUEST_PAGE_SIZE, hasField: true, sort: {id: 'asc'}},
  },
  detailSalary: {
    path: 'nhan-vien-detail-salary',
    method: 'GET',
    data: {query: -1},
  },
  detailSalaryByYear: {
    path: 'nv-salary-detail-year',
    method: 'GET',
    data: {query: -1, hasField: true},
  },
  detailSalaryByMonth: {
    path: 'nv-salary-detail-month',
    method: 'GET',
    data: {query: -1, hasField: true},
  },
  storeSalary: {
    path: 'nhan-vien-salary',
    method: 'POST',
  },
  listChamCong: {
    path: 'nv-cham-cong',
    method: 'GET',
    data: {query: -1, page: 1, limit: REQUEST_BIG_PAGE_SIZE, hasField: true},
  },
  detailChamCong: {
    path: 'nv-cham-cong',
    method: 'GET',
    data: {query: -1},
  },
  updateChamCong: {
    path: 'nv-cham-cong',
    method: 'PUT',
    data: {query: -1},
  },
  dangKyNghiPhep: {
    path: 'nv-dang-ky-nghi-phep',
    method: 'POST',
  },
  huyDangKyNghiPhep: {
    path: 'nv-dang-ky-nghi-phep',
    method: 'DELETE',
  },
  listLuongTongHop: {
    path: 'nv-salary-store',
    method: 'GET',
    data: {page: 1, limit: REQUEST_BIG_PAGE_SIZE, sort: {id: 'asc'}, hasField: true},
  },
  detailLuongTongHop: {
    path: 'nv-salary-store',
    method: 'GET',
    data: {query: -1, hasField: true},
  },
  reportLuong: {
    path: 'nv-salary-report',
    method: 'GET',
    data: {query: null, page: 1, limit: REQUEST_BIG_PAGE_SIZE, hasField: false},
  },
  topDoanhThuThuc: {
    path: 'top-doanh-thu-thuc',
    method: 'GET',
    data: {query: -1},
  },
}
