import { Fragment } from 'react';
import _ from 'lodash';
import Awesome from '@components2/Awesome';
import { PHO_GIAM_DOC_PERM_KEY, GCT_UPDATE_FEE_DEADLINE, TRUONG_NHOM_NVKD_PERM_KEY } from './constants';

export const sleep = (ms = 500) => new Promise(resolve => setTimeout(resolve, ms))

export const isMatching = (subject, str) => RegExp(str.replace(/ /g, ''), 'i').test(subject.replace(/ /g, ''))

// alternative _.pick
export const pick = (data, keys) => {
  return Object.entries(data)
    .filter(o => keys.indexOf(o[0]) !== -1)
    .reduce((acc, val) => ({...acc, [val[0]]: val[1]}), {})
}

/**
 *
 * Convert obj or array to className string
 * ['col-md-6', 'col-xs-12'] convert to 'col-md-6 col-xs-12'
 * {'col-md-6': true, 'col-xs-12': false} convert to 'col-md-6'
 *
 * */
export const parseClassName = (params) => {
  if (typeof params === 'string') {
    return params
  }

  if (Array.isArray(params)) {
    return params.filter(o => o !== null && o !== 0 && typeof params !== 'undefined').join(' ')
  }

  if (typeof params !== 'object') {
    return null
  }

  return Object.entries(params).filter(arr => arr[1] === true).map(arr => arr[0]).join(' ')
}

export const copyToClipboard = str => {
  if ('clipboard' in navigator) {
    return navigator.clipboard.writeText(str)
  }

  const el = document.createElement('textarea')
  el.value = str
  document.body.appendChild(el)
  el.select()
  document.execCommand('copy')
  document.body.removeChild(el)
}

export const copyHtmlToClipboard = (contentId) => {
  document.getElementById(contentId).focus()
  document.execCommand('selectAll', false, null)
  document.execCommand('copy')
}

export const extractRouteAction = location => {
  const {pathname, search} = location

  const action = pathname.split('/').pop()
  const basename = pathname.substring(0, pathname.indexOf(action) - 1)
  const query = {}

  const searchParams = new URLSearchParams(search)
  searchParams.forEach((value, key) => {
    query[key] = value
  })

  return {basename, action, query}
}

export const studlyCase = (str, {lowerFirstWord} = {}) => {
  const pattern = /(_|-|\s)/

  let strResult = str.split(pattern)
    .filter(v => !['_', '-', ' '].includes(v))
    .map(v => v.charAt(0).toUpperCase() + v.slice(1))
    .join('')

  if (lowerFirstWord) {
    strResult = _.lowerFirst(strResult)
  }

  return strResult
}

export const fillPlacehoder = (val, searching, replacing) => {
  if (typeof searching === 'string' && typeof replacing === 'string') {
    return val.replace(searching, replacing)
  }

  if (!Array.isArray(searching) || (Array.isArray(replacing) && searching.length !== replacing.length)) {
    return val
  }

  return _.zip(searching, replacing)
    .reduce((acc, cur) => acc.replace(cur[0], cur[1]), val)
}

export const extractSuffix = (str, params = {}) => {
  if (typeof str !== 'string') {
    return false
  }

  const {separator = '-'} = params

  return str.split(separator).pop()
}

export const filterErrorAction = params => {
  const newValues = Object.entries(params)
    .reduce((acc, cur) => {
      return {...acc, [cur[0]]: typeof cur[1] !== 'function' ? cur[1] : 'function'}
    }, {})

  return JSON.stringify(newValues)
}

export const convertDateToString = value => {
  if (!(value instanceof Date)) {
    return {date: null, time: null, datetime: null}
  }

  const date = [
    value.getFullYear(),
    `0${value.getMonth() + 1}`.slice(-2),
    `0${value.getDate()}`.slice(-2),
  ].join('-')

  const time = value.toTimeString().substr(0, 5)
  const datetime = `${date} ${time}`

  return {date, time, datetime}
}

export const convertStringToDate = value => {
  if (value instanceof Date) {
    return value
  }

  if (typeof value !== 'string') {
    return null
  }

  if (value.length === 16 || value.length === 10) {
    return new Date(value)
  } else if (value.length === 5) {
    return new Date(`${(new Date).toISOString().substr(0, 10)} ${value}`)
  }

  return null
}

export const convertDbDateToHuman = value => {
  if (typeof value !== 'string' && !(value instanceof Date)) {
    return null;
  }

  const oDate = value instanceof Date ? value : convertStringToDate(value);

  const date = [
    `0${oDate.getDate()}`.slice(-2),
    `0${oDate.getMonth() + 1}`.slice(-2),
    oDate.getFullYear(),
  ].join('/');

  const dateShort  = [
    `0${oDate.getDate()}`.slice(-2),
    `0${oDate.getMonth() + 1}`.slice(-2),
    `${oDate.getFullYear()}`.substring(2),
  ].join('/');

  const time = oDate.toTimeString().substring(0, 5);
  const datetime = `${time} ${date}`;
  const datetimeShort = `${time} ${dateShort}`;

  return { date, dateShort, time, datetime, datetimeShort };
}

export const genRanKey = (prefix = 'key') => {
  return `${prefix}-${Date.now()}`
}

export const slugCase = value => {
  let slug = (`${value}`).trim().toLowerCase()

  if (slug === '') {
    return ''
  }

  //Đổi ký tự có dấu thành không dấu
  slug = slug.replace(/á|à|ả|ạ|ã|ă|ắ|ằ|ẳ|ẵ|ặ|â|ấ|ầ|ẩ|ẫ|ậ/gi, 'a')
  slug = slug.replace(/é|è|ẻ|ẽ|ẹ|ê|ế|ề|ể|ễ|ệ/gi, 'e')
  slug = slug.replace(/i|í|ì|ỉ|ĩ|ị/gi, 'i')
  slug = slug.replace(/ó|ò|ỏ|õ|ọ|ô|ố|ồ|ổ|ỗ|ộ|ơ|ớ|ờ|ở|ỡ|ợ/gi, 'o')
  slug = slug.replace(/ú|ù|ủ|ũ|ụ|ư|ứ|ừ|ử|ữ|ự/gi, 'u')
  slug = slug.replace(/ý|ỳ|ỷ|ỹ|ỵ/gi, 'y')
  slug = slug.replace(/đ/gi, 'd')

  //Xóa các ký tự đặt biệt
  slug = slug.replace(/\`|\~|\!|\@|\#|\||\$|\%|\^|\&|\*|\(|\)|\+|\=|\,|\.|\/|\?|\>|\<|\'|\"|\:|\|_/gi, '')

  //Đổi khoảng trắng thành ký tự gạch ngang
  slug = slug.replace(/ /gi, '-')

  //Đổi nhiều ký tự gạch ngang liên tiếp thành 1 ký tự gạch ngang
  //Phòng trường hợp người nhập vào quá nhiều ký tự trắng
  slug = slug.replace(/\-\-\-\-\-/gi, '-')
  slug = slug.replace(/\-\-\-\-/gi, '-')
  slug = slug.replace(/\-\-\-/gi, '-')
  slug = slug.replace(/\-\-/gi, '-')
  //Xóa các ký tự gạch ngang ở đầu và cuối
  slug = `@${slug}@`

  return slug.replace(/\@\-|\-\@|\@/gi, '')
}

export const injectOptionKey = (data, oMapper) => {
  const arrData = (Array.isArray(data) ? _.cloneDeep(data) : [_.cloneDeep(data)])
    .map(o => {
      for (const realKey in oMapper) {
        if (realKey in o) {
          _.set(o, oMapper[realKey], _.cloneDeep(o[realKey]))
        }
      }

      return o
    })

  return Array.isArray(data) ? arrData : arrData[0]
}

export const convertObjectToStr = data => {
  try {
    if (typeof data === 'string') {
      return data
    }

    return JSON.stringify(data, null, 2)
  } catch (error) {
    console.log('convertObjectToStr FAIL', error)

    return '[]'
  }
}

export const formatVndMoney = (value, {hasVnd = false, useCommasSeperator = false} = {}) => {
  const money = value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, !useCommasSeperator ? '$1 ' : '$1,')

  return `${money}${hasVnd ? ' vnđ' : ''}`
}

export const formatPercent = (value, {isRawValue = true} = {}) => {
  let newValue = parseFloat(value)

  if (isNaN(newValue) || newValue === 0) {
    return isRawValue ? 0 : `${0}%`
  }

  if (isRawValue) {
    return newValue
  }

  newValue = `${newValue.toFixed(1)}%`

  return newValue.replace('.0', '')
}

export const calTaxValue = (total, taxPercent, {isFormatMoney = false} = {}) => {
  if (isNaN(total) || isNaN(taxPercent)) {
    return 0
  }

  const value = Math.round(total * (taxPercent / 100))

  return isFormatMoney ? formatVndMoney(value) : value
}

export const calPlusTaxValue = (total, taxPercent, {isFormatMoney = false} = {}) => {
  if (isNaN(total) || isNaN(taxPercent)) {
    return 0
  }

  const value = total + Math.round((taxPercent / 100 * total))

  return isFormatMoney ? formatVndMoney(value) : value
}

export const calPercentValue = (total, value, {isRawValue = true} = {}) => {
  const perValue = total !== 0 ? value / total * 100 : 0

  // return isRawValue ? parseFloat(perValue.toFixed(1)) : formatPercent(perValue, {isRawValue: false})
  return isRawValue ? perValue : formatPercent(perValue, {isRawValue: false})
}

export const genUniqIdBasedOnTime = (prefix = null) => {
  const strTime = Date.now().toString();
  const uniqueId = strTime.substring(strTime.length - 8)

  return prefix === null ? uniqueId : `${prefix}-${uniqueId}`
}

export const normalizeErrorsRes = (values) => {
  if (typeof values !== 'object') {
    return {}
  }

  return _.mapKeys(values, (v, k) => {
    const pos = k.indexOf('row-')

    return pos !== 0 ? k : k.substr(4)
  })
}

export const pieChartConvertPercent = data => {
  const total = data.reduce((acc, cur) => (acc + cur), 0)

  return data.map(v => calPercentValue(total, v).toFixed(0))
}

export const extractDvCodesOfPgdPerms = userAbility => {
  const foundIdx = userAbility.findIndex(o => o.subject === PHO_GIAM_DOC_PERM_KEY)

  if (foundIdx === -1) {
    return []
  }

  return _.get(userAbility[foundIdx], 'conditions.dich-vu-quan-ly', [])
}

export const extractNvIdForTruongNvkdPerm = userAbility => {
  const found = userAbility.find(o => o.subject === TRUONG_NHOM_NVKD_PERM_KEY)

  return _.get(found || {}, 'conditions.danh-sach-nvkd', []);
}

export const convertTextToNode = text => {
  if (text === null || text.trim() === '') {
    return text
  }

  const parts = text.split("\n")

  return parts.map((str, idx) => {
    return (
      <Fragment key={idx}>
        <span>{str}</span>
        <br />
      </Fragment>
    )
  })
}

export const calFinalDateForUpdating = ({finalDate, isStrFormat = true}) => {
  const startDay = (new Date())
  startDay.setDate(1)
  startDay.setHours(0)
  startDay.setMinutes(0)
  startDay.setSeconds(0)

  const basedDate = finalDate ? convertStringToDate(finalDate) : startDay
  basedDate.setMonth(basedDate.getMonth() + 1)
  basedDate.setDate(GCT_UPDATE_FEE_DEADLINE)

  return isStrFormat ? convertDbDateToHuman(basedDate).date : basedDate
}

export const removeHtml = (str) => {
  const tmp = document.createElement('div')
  tmp.innerHTML = str
  return tmp.textContent || tmp.innerText || ''
}

export const createNewLinkIcon = (path) => ({
  action: 'createNew',
  label: 'commons.create',
  isUsedI18n: true,
  isUsedTooltip: true,
  icon: <Awesome value='fas fa-plus' />,
  path,
});

export const ctyDaiDienLabels = {
  ten_cty: 'Tên cty',
  dia_chi: 'Địa chỉ',
  dien_thoai: 'Điện thoại',
  mst: 'MST',
  tai_khoan: 'Tài khoản',
  nguoi_dai_dien: 'Người đại diện',
  chuc_vu: 'Chức vụ',
};

export const wordOpenCode = (id) => `\${${id}}`;

export const wordCloseCode = (id) => `\${/${id}}`;

export const decodeHtmlEntity = (str) => {
  const txt = document.createElement('textarea');
  txt.innerHTML = str;

  return txt.value;
};

export const isUuid = (str) => {
  if (typeof str !== 'string') {
    return false;
  }

  const regex = new RegExp('^[0-9A-F]{8}-[0-9A-F]{4}-[4][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$', 'i');

  return regex.test(str);
};

export const convertDaySliderToDbDate = (startNum, endNum) => {
  const myAddDays = (addDays) => {
    const o = new Date();
    o.setDate(o.getDate() + addDays);

    return [
      o.getFullYear(),
      `0${o.getMonth() + 1}`.slice(-2),
      `0${o.getDate()}`.slice(-2),
    ].join('-');
  };

  return [myAddDays(startNum), myAddDays(endNum)];
};

export const parseTableTitle = (str) => {
  const arr = str.split(' / ');

  if (arr.length === 1) {
    return [undefined, str];
  }

  return [
    arr.slice(0, -1).join(' / '),
    arr.slice(-1),
  ];
};
