import {buildRequestParamRest} from './helpers'
import {REQUEST_BIG_PAGE_SIZE} from '@utils/constants'

export default {
  url: `${process.env.REACT_APP_API_URL}/iam/`,
  ...buildRequestParamRest({path: 'module', suffix: 'Module'}),
  ...buildRequestParamRest({path: 'permission', suffix: 'Permission'}),
  ...buildRequestParamRest({path: 'option', suffix: 'Option'}),
  listModulePermission: {
    path: 'permission',
    method: 'GET',
    data: {
      limit: REQUEST_BIG_PAGE_SIZE,
      sort: {id: 'asc'},
      wheres: [],
    },
  },
  changeMyPassword: {
    path: 'change-my-passwd',
    method: 'PUT',
  },
}
