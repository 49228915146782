// ** Third party components
import axios from 'axios'
import _ from 'lodash'

// ** Bullt-in components
import {isBlobResponse, parseDownloadResponse} from './requestHelpers'

const request = params => {
  const {url, data} = params

  const headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  }

  if (_.has(params, 'token')) {
    _.set(headers, 'Authorization', `Bearer ${params.token}`)
  }

  let {method} = params

  if (method.toLowerCase() === 'get'
    && typeof data === 'object'
    && Object.keys(data).length !== 0) {
    method = 'POST'
    headers['X-HTTP-Method-Override'] = 'GET'
  }

  return axios({
    url,
    method,
    data,
    headers,
    responseType: params.isDownload === true ? 'blob' : '',
  }).then(response => {
    // detect download file
    if (isBlobResponse(response)) {
      return Promise.resolve(parseDownloadResponse(response))
    }

    return Promise.resolve(response.data || {})
  }).catch(error => {
    const {status, data} = error.response

    if (status === 401) {
      return Promise.reject('unauthenticated')
    }

    if (_.has(data, 'error.message')) {
      return Promise.reject(data.error.message)
    }

    return Promise.resolve(data)
  })
}

export default request
