import { buildRequestParamRest } from './helpers';

export default {
  url: `${process.env.REACT_APP_APIV1_URL}/erp/`,
  ...buildRequestParamRest({path: 'quan-ly-hs-thiet-bi', suffix: 'HsThietBi'}),
  ...buildRequestParamRest({path: 'quan-ly-ho-so', suffix: 'HoSo'}),
  downloadHsThietBiKiemDinhTpl: {
    path: 'hs-thiet-bi/download-kiem-dinh-templates',
    method: 'GET',
  },
  uploadHsThietBiKiemDinhTpl: {
    path: 'hs-thiet-bi/upload-kiem-dinh-templates',
    method: 'POST',
  },
  clearAllTpls: {
    path: 'hs-thiet-bi/clear-all-templates',
    method: 'POST',
  },
  hsThietBiOptions: {
    path: 'hs-thiet-bi/options',
    method: 'GET',
  },
  updateAssigneesForHoSo: {
    path: 'quan-ly-hs-update-assignees',
    method: 'PUT',
    data: { query: -1 },
  },
  prepareExportingForHoSo: {
    path: 'quan-ly-hs-prepare-exporting',
    method: 'GET',
    data: { query: -1 },
  },
  downloadDocsOfHoSo: {
    path: 'quan-ly-hs-download-docs',
    method: 'GET',
    data: { query: -1 },
  },
  rsyncFreeInput: {
    path: 'quan-ly-hs-resync-free-inputs',
    method: 'GET',
    data: { query: -1 },
  },
  configUnitOfHoSo: {
    path: 'quan-ly-hs-config-unit',
    method: 'PUT',
    data: { query: -1 },
  },
  counterByStatusOfHoSo: {
    path: 'quan-ly-hs-counter-by-status',
    method: 'GET',
  },
  reportByYearForHoSo: {
    path: 'quan-ly-hs-report-by-year',
    method: 'GET',
    data: {
      query: 2000,
      dichvu_code: 'undefined',
    },
  },
  exportReportForHoSo: {
    path: 'quan-ly-hs-export-report',
    method: 'GET',
    data: {
      query: 2000,
      dichvu_code: 'undefined',
    },
  },
}
