import {all, takeEvery} from "redux-saga/effects"

// ** Import actions
import {dataSourceRequest, simpleReq} from "./index"

// ** Saga watching
import requestWatching from './requestWatching'
import dataSourceWatching from "./dataSourceWatching"

export default function* rootSaga() {
  yield all([
    takeEvery(dataSourceRequest.toString(), dataSourceWatching),
    takeEvery(simpleReq.toString(), requestWatching),
  ])
}
