import PropTypes from 'prop-types';
import clsx from 'clsx';

const Awesome = ({value, size, className, title, onClick}) => {
  const newSize = typeof size === 'number' ? size : 1;
  const hasCallback = typeof onClick === 'function';

  return (
    <span
      title={title}
      className={clsx(className, (hasCallback || !!title) && 'cursor-pointer')}
      style={{fontSize: `${newSize}rem`}}
      onClick={() => {
        if (hasCallback) {
          onClick()
        }
      }}
    >
      <i className={value}></i>
    </span>
  )
}

Awesome.propTypes = {
  value: PropTypes.string.isRequired,
  title: PropTypes.string,
  size: PropTypes.number,
  className: PropTypes.any,
  onClick: PropTypes.func,
}

Awesome.defaultProps = {
  title: null,
  size: 1,
  className: null,
  onClick: undefined,
}

export default Awesome
