import { createEntityAdapter } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import _ from 'lodash';

import { defSourceId, sliceName } from './index';

const entityAdapter = createEntityAdapter({});

const selectSource = (state, payload = {}) => {
  const { sourceId = defSourceId } = payload;
  return state[sliceName][sourceId] || {};
};

const selectOption = (__, { data }) => (Array.isArray(data) ? data : []);

const getOption = createSelector(
  [selectSource, selectOption],
  (sourceState, expects) => {
    if (!Array.isArray(expects) || expects.length === 0) {
      return [];
    }

    return expects.map(dotKey => _.get(sourceState, dotKey));
  }
);

const selectors = entityAdapter.getSelectors((state, { sourceId = defSourceId }) => state[sliceName][sourceId] || { ids: [] });

const getData = createSelector(
  selectors.selectAll,
  data => data
);

const getFinalData = createSelector(
  selectors.selectAll,
  data => (data.length !== 0 ? { ...data.slice(-1)[0] } : {})
);

const getSpecifiedRow = createSelector(
  [selectors.selectEntities, selectOption],
  (data, expects) => expects.map(v => _.get(data, v, null))
);

const selectColumnsOfRows = createSelector(
  [selectors.selectEntities, selectOption],
  (data, expects) => {
    const myData = expects.map(v => _.map(data, v));

    return expects.length === 1 ? myData[0] : myData;
  }
)

export { entityAdapter, getOption, getData, getFinalData, getSpecifiedRow, selectColumnsOfRows };
