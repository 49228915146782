import {REQUEST_BIG_PAGE_SIZE} from '@utils/constants'
import {buildRequestParamRest} from './helpers'

export default {
  url: `${process.env.REACT_APP_API_URL}/ci/`,
  options: {
    path: 'form-option',
    method: 'GET',
    data: {
      query: -1,
    }
  },
  fields: {
    path: 'setting/showByCode',
    method: 'GET',
    data: {
      code: 'null',
      hasExtractValueOnly: true,
    }
  },
  settingOptions: {
    path: 'setting/showByCode',
    method: 'GET',
    data: {
      code: -1,
      hasExtractOptionValue: true,
    }
  },
  contacts: {
    path: 'contact',
    method: 'GET',
    data: {
      page: 1,
      limit: REQUEST_BIG_PAGE_SIZE,
    }
  },
  ...buildRequestParamRest({path: 'contact', suffix: 'Contact'}),
}
