import {call, put, fork} from 'redux-saga/effects'
import _ from 'lodash'

import {injectTokenHeader, processStackActions} from './helper'
import {logout} from '../user'
import request from '../../utility/request'
import {normalizeErrorsRes} from '@utils/basicHelper'

function *processSuccessAction(params) {
  const {successAction, successKeyToWrite, response} = params

  const actionParam = _.cloneDeep(successAction)
  _.set(actionParam, ['payload', successKeyToWrite].join('.'), response)

  if (typeof response === 'object' && 'errors' in response) {
    _.set(actionParam, 'payload.data.errors', normalizeErrorsRes(response.errors))
  }

  yield put(actionParam)
}

function* callApi(payload) {
  const {
    reqParam,
    successAction,
    successKeyToWrite,
    autoExtractFirstRow = false,
    autoExtractOptions = false,
    beforeActions = [],
    afterActions = []
  } = payload

  if (beforeActions.length !== 0) {
    yield call(processStackActions, {actions: beforeActions})
  }

  try {
    let response = yield call(request, reqParam)

    if (autoExtractFirstRow === true && _.has(response, 'rows.0')) {
      response = _.get(response, 'rows.0')
    }

    if (autoExtractFirstRow === false && autoExtractOptions === true && _.has(response, 'rows')) {
      response = _.get(response, 'rows')
    }

    if (successAction && successKeyToWrite) {
      yield call(processSuccessAction, {successAction, successKeyToWrite, response})
    }

    if (afterActions.length !== 0) {
      yield call(processStackActions, {actions: afterActions})
    }
  } catch (error) {
    // Unauthorized
    if (error === 'unauthenticated') {
      yield put(logout())
    }
  }
}

function* watching(action) {
  // inject Bearer token
  yield call(injectTokenHeader)

  yield fork(callApi, action.payload)
}

export default watching
