import {REQUEST_PAGE_SIZE} from '../../utility/constants'

export default {
  url: `${process.env.REACT_APP_API_URL}/erp/`,
  listKhachHangSoHuu: {
    path: 'khach-hang-so-huu',
    method: 'GET',
    data: {
      page: 1,
      limit: REQUEST_PAGE_SIZE,
      hasField: true,
    }
  },
  ownerKhachHangSoHuu: {
    path: 'khach-hang-so-huu-owner',
    method: 'GET',
    data: {
      page: 1,
      limit: REQUEST_PAGE_SIZE
    }
  },
  detailKhachHangSoHuu: {
    path: 'khach-hang-so-huu',
    method: 'GET',
    data: {
      query: -1,
      hasField: true,
    }
  },
  nhanSoHuu: {
    path: 'khach-hang-so-huu',
    method: 'POST',
  },
  baoGia: {
    path: 'kinh-doanh/bao-gia',
    method: 'GET',
    data: {
      ciCode: 'kinh-doanh::bao-gia-table',
      page: 1,
      limit: REQUEST_PAGE_SIZE,
    }
  },
  soTheoDoi: {
    path: 'kinh-doanh/so-theo-doi',
    method: 'GET',
    data: {
      ciCode: 'kinh-doanh::khach-hang-table',
      page: 1,
      limit: REQUEST_PAGE_SIZE,
    }
  }
}
