import { put, fork } from 'redux-saga/effects'

function* caller(payload) {
  if (!Array.isArray(payload)) {
    return
  }

  let counter = 0

  while (counter < payload.length) {
    yield put(payload[counter])
    counter++
  }
}

function* watching(action) {
  yield fork(caller, action.payload)
}

export default watching
