export default {
  url: `${process.env.REACT_APP_API_URL}/`,
  download: {
    path: 'core/download',
    method: 'GET',
    data: {
      query: -1
    }
  },
  export: {
    path: 'core/export',
    method: 'GET',
    data: {
      query: -1,
      code: -1,
    }
  },
  history: {
    path: 'core/history',
    method: 'GET',
    data: {
      selects: ['id', 'title', 'content', 'created_at'],
      sort: {id: 'asc'},
      limit: 1000,
      wheres: [],
    }
  },
}
