import axios from 'axios'
import {select, put} from 'redux-saga/effects'
import _ from 'lodash'

import {userData} from "../user/selectors"

export function* injectTokenHeader() {
  const [token] = yield select(state => userData(state, ['oauth.access_token']))

  if (!token) {
    return
  }

  axios.defaults.headers.common.Authorization = `Bearer ${token}`
}

/**
 * Parse fields of DataSource
 * @param array data
 */
export const parseDsFields = data => {
  const primaryKey = _.has(data, '0.dataIndex') ? 'dataIndex' : 'id'

  const fieldsLabel = data.reduce((acc, cur) => {
    if (!_.has(cur, 'title')) {
      return acc
    }

    return {...acc, [cur[primaryKey]]: cur.title || null}
  }, {})

  return {fieldsLabel}
}

export function *processStackActions(params) {
  const {actions} = params
  let counter = 0

  while (counter < actions.length) {
    if (actions[counter] === null) {
      continue
    }

    yield put(actions[counter])
    counter++
  }
}
