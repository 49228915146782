export default {
  'iam.loginFail': 'Đăng nhập không thành công!',
  'iam.login': 'Đăng nhập',
  'iam.logout': 'Đăng xuất',
  'iam.chucVu': 'Chức vụ: {name}',
  'iam.deleteModule': 'Đang xóa module: {name}',
  'iam.deletePermission': 'Đang xóa permission: {name}',
  'iam.deleteOption': 'Đang xóa option: {name}',
  'iam.copySlug': 'Sao chép slug',
  'iam.canNotPermission': 'Bạn không có quyền {name}. Vui lòng liên hệ quản trị viên!',
  'users.title': 'Danh sách người dùng',
  'users.create': 'Tạo mới người dùng',
  'users.update': 'Cập nhật người dùng',
  'users.delete': 'Xóa người dùng: {name} ({email})',
  'users.profile': 'Trang cá nhân',
  'erpGlobal.soLieuTongHop': 'Số liệu tổng hợp',
  'erpNhanVien.title': 'Danh sách nhân viên',
  'erpNhanVien.create': 'Tạo nhân viên mới',
  'erpNhanVien.update': 'Cập nhật thông tin nhân viên',
  'erpNhanVien.generateCode': 'Nạp lại mã nhân viên',
  'erpNhanVien.allOption': 'Tất cả nhân viên',
  'erpNhanVien.delete': 'Đang xóa nhân viên: {name}',
  'erpNhanVien.resetPassword': 'Đặt lại mật khẩu cho nhân viên {name}. Vui lòng nhập mật khẩu mới:',
  'erpNhanVien.defSelectOption': '---Chọn nhân viên---',
  'erpNhanVien.phieuLuongChiTietNotifyBg': 'Chỉ gồm những BG thuộc NVKD được nhận hoa hồng',
  'erpNhanVien.phieuLuongChiTietNotifyGct': 'Chỉ gồm những GCT có phát sinh chi phí',
  'erpNhanVien.soHieuKdv': 'Số hiệu KĐV',
  'erpKhachHang.title': 'Danh sách khách hàng',
  'erpKhachHang.create': 'Tạo khách hàng mới',
  'erpKhachHang.update': 'Cập nhật thông tin khách hàng',
  'erpKhachHang.lienHeName': 'Họ tên',
  'erpKhachHang.lienHePhone': 'Điện thoại',
  'erpKhachHang.choose': 'Chọn khách hàng',
  'erpKhachHang.delete': 'Đang xóa khách hàng: {name}',
  'erpKhachHang.deleteContact': 'Đang xóa người liên hệ: {name}',
  'erpKinhDoanh.matQuyenSoHuu': 'Sau {deadline} mất quyền sở hũu',
  'erpKinhDoanh.updatedBy': 'Cập nhật bởi {user}',
  'erpKinhDoanh.thoiGianTheoDoi': '{user} đang theo dõi. Còn {days} ngày sẽ mất quyền sở hữu.',
  'erpKinhDoanh.chonTheoDoiDv': 'Chọn theo dõi dịch vụ {name}.',
  'erpKinhDoanh.soHuuMaiMai': 'Dịch vụ này được sở hữu mãi mãi bởi {user}',
  'erpKinhDoanh.taoBaoGia': 'Tạo báo giá',
  'erpKinhDoanh.acceptSoHuu': 'Nhận sở hữu',
  'erpKinhDoanh.acceptOwner': 'Nhận sở hữu dịch vụ {dichVu} của cty {cty}',
  'erpKinhDoanh.ownerByAndDeadline': '{owner} tới ngày {deadline}',
  'erpBaoGia.createHangMucGroup': 'Tạo nhóm mới',
  'erpBaoGia.updateHangMucGroup': 'Cập nhật nhóm',
  'erpBaoGia.deleteHangMucGroup': 'Đang xóa nhóm {name}',
  'erpBaoGia.createHangMucBaoGia': 'Tạo hạng mục mới',
  'erpBaoGia.updateHangMucBaoGia': 'Cập nhật hạng mục',
  'erpBaoGia.deleteHangMucBaoGia': 'Đang xóa hạng mục {name} của dịch vụ {service}',
  'erpBaoGia.createBaoGiaOfService': 'Tạo báo giá cho khách hàng {name} của dịch vụ {service}',
  'erpBaoGia.baoGiaChiTietTitle': 'Báo giá chi tiết',
  'erpBaoGia.hangMucHeader.title': 'BẢNG BÁO GIÁ {name}',
  'erpBaoGia.chiPhiGiangVien': 'Chi phí GV: {price} vnđ',
  'erpBaoGia.save': 'Lưu báo giá',
  'erpBaoGia.autoFillKhachHang': 'Lấy thông tin từ báo giá',
  'erpBaoGia.nvTaoBaoGia': 'NV tạo: {name}',
  'erpBaoGia.chotBaoGia': 'Thực hiện chốt báo giá {name}',
  'erpBaoGia.huyBaoGia': 'Thực hiện {action} báo giá {name}',
  'erpBaoGia.notifyHuyBaoGia': 'Lưu ý cần tiến hành {name} thuộc báo giá này trước khi thực hiện. Báo giá sau hủy sẽ không thể chỉnh sửa!',
  'erpBaoGia.taoGct': 'Tạo giấy công tác cho báo giá {name}',
  'erpBaoGia.haveExportBilling': 'Có xuất hóa đơn',
  'erpBaoGia.alertBeforeSaveHangMuc': 'Vui lòng lưu báo giá trước khi thực hiện chức năng này.',
  'erpBaoGia.alertCreatedBg': '*Lưu ý: tồn tại báo giá {name} đang trong quá trình thực hiện.',
  'erpBaoGia.chonCty': 'Chọn cty đại diện',
  'erpBaoGia.huyHangMuc': 'Hủy hạng mục',
  'erpBaoGia.restoreHuyHangMuc': 'Phục hồi Hủy hạng mục',
  'erpBaoGia.notifyHuyHangMuc': ' *Lưu ý: Hạng mục bị hủy sẽ không được tính vào báo giá.',
  'erpBaoGia.notifySendHoanTatBaoGia': '*Lưu ý: sau khi Kế toán duyệt "Hoàn tất báo giá" sẽ {name} và thời gian duyệt sẽ dùng làm cơ sở lưu trữ.',
  'erpBaoGia.sendRequirementToPerson': 'Gửi yêu cầu {category} đến {name}',
  'erpBaoGia.hoSoInfoTitle': 'Thông tin đã gửi',
  'erpBaoGia.hasNotPermission': 'Bạn không có quyền truy cập BG {name}!',
  'erpBaoGia.taoBaoGiaThanhCong': 'Tạo báo giá thành công!',
  'erpBaoGia.quanLyNhom': 'Quản lý theo nhóm',
  'erpBaoGia.clone': 'Sao chép BG',
  'erpBaoGia.cloneWithText': 'Sao chép BG {name}',
  'erpBaoGia.assigner': 'NVKD',
  'erpGct.createGctTitle': 'Tạo giấy công tác',
  'erpGct.gctDetailTitle': 'Giấy công tác chi tiết',
  'erpGct.initializingGct': 'Đang khởi tạo giấy công tác.',
  'erpGct.canNotCreateGct': 'Không thể tạo giấy công tác.',
  'erpGct.canNotLoadGct': 'Giấy công tác không tồn tại.',
  'erpGct.fullCreatedGct': 'Đã tạo đủ GCT tương ứng báo giá {name} hoặc hạng mục BG đang trống.',
  'erpGct.fullCreatedGctChiTiet': 'Đã tạo đủ hạng mục công việc tương ứng giấy công tác {name}',
  'erpGct.nvkdPhuTrach': 'NVKD phụ trách: {name}',
  'erpGct.nvkdPhuTrachText': 'NVKD phụ trách',
  'erpGct.nvThucHien': 'NV đang thực hiện: ',
  'erpGct.inputQuantityMax': 'Tối đa {number}.',
  'erpGct.save': 'Lưu giấy công tác',
  'erpGct.changeStartedAtNotification': 'Thay đổi ngày thực hiện từ {oldValue} thành {newValue} sẽ ảnh hưởng đến lịch nhân viên.',
  'erpGct.emptyStartedAtNotification': '*Lưu ý: "Ngày thực hiện" chưa được nhập nên không hiển thị lịch hoạt động',
  'erpGct.defSelectOptionOfFee': '---Chọn loại phí---',
  'erpGct.acceptGct': 'Nhận GCT',
  'erpGct.changeAssigner': 'Chuyển GCT',
  'erpGct.notifyThucHienHoanTat': 'Trưởng nhóm và các thành viên {name} các hạng mục công việc trong GCT này phải không?',
  'erpGct.notifyUndoThucHienHoanTat': '{action} trạng thái {name} sẽ không thể chuyển GCT cho Văn thư?',
  'erpGct.notifyKiemTraHoanTat': 'Văn thư {name} các hạng mục trên phần mềm với tài liệu bàn giao từ trưởng nhóm.',
  'erpGct.notifyUndoKiemTraHoanTat': 'Bạn đang thực hiện {action} trạng thái "{name}" ngày {onDate} khỏi GCT. Vui lòng chọn hình thức thực hiện:',
  'erpGct.notifyThuHoiBienBan': 'Văn thư {name} các biên bản thu hồi từ trưởng nhóm.',
  'erpGct.notifyUndoThuHoiBienBan': '{action} trạng thái {name} khỏi GCT.',
  'erpGct.notifyAcceptGct': '{name} để thực hiện cập nhật tiến độ công việc.',
  'erpGct.notifyNotYetAccepted': 'Vui lòng chọn {name} mới có thể chỉnh sửa!',
  'erpGct.notifyDestroyGct': 'Hủy GCT {name}',
  'erpGct.notifyBaoGiaChanged': 'Báo giá có thay đổi. Vui lòng nạp lại trang! (nhấn phím F5 trên windows)',
  'erpGct.notifyMakeOkForAllHm': 'Ghi nhận kết quả {name} cho {all} hạng mục công việc.',
  'erpGct.waitingToAcceptedBy': 'Chờ nhận bởi {name}',
  'erpGct.waitingHangMucBiHuy': 'Hạng mục này đã bị hủy bởi NVKD',
  'erpGct.hasNotPermission': 'Bạn không có quyền truy cập GCT {name}!',
  'erpGct.chiPhiThucHienOver20': 'Tổng % kĩ thuật và thực hiện đang lớn hơn 20%',
  'erpGct.updateFeeDeadline': 'BG đã xuất hóa đơn/pt & GCT đã kiểm, cần cập nhật chi phí trước ngày {name}',
  'erpGct.denyAfterFinalDateForUpdating': 'BG đã xuất hóa đơn. Việc gỡ và đánh dấu lại cần thực hiện trước ngày {name}. Nếu không, hoa hồng KT, lương doanh thu sẽ được tính cho lương tháng sau.',
  'erpGct.changeAssignerBy': 'GCT này được chuyển bởi {name}',
  'erpChamCong.thoiGianNghiPhep': 'Nghỉ {dayNumber} ngày từ {absenceAt}',
  'erpChamCong.huyDangKyNghiPhepConfirmation': 'Hủy ngày phép {absenceAt} của nhân viên {name}',
  'erpLichGv.destroyLich': 'Xóa lịch {name} vào ngày {onDay}',
  'erpLichGv.dayPartInfo': 'Buổi {order}: {start} - {end}',
  'erpLichGv.chiPhiGvTotal': 'Tổng cp: {number}',
  'erpLichGv.note': 'Ghi chú lịch',
  'erpLichGv.typeChiPhiHuanLuyen': 'CP huấn luyện',
  'erpLichGv.typeCongTacPhi': 'Công tác phí',
  'erpLichGv.typeChiPhiKhac': 'Chi phí khác',
  'erpLichGv.generalInfo': 'Thông tin chung',
  'erpLichGv.actionTitle': '{action, select,\n' +
    '    update {Cập nhật}\n' +
    '    append {Thêm}\n' +
    '    other {Tạo mới}\n' +
    '} Lịch GV',
  'erpLichGv.lichGvTitle': 'Lịch giảng viên',
  'erpLichGv.addNewlichGvTitle': 'Thêm Lịch GV',
  'erpLichGv.lichGvTitleWithName': 'Lịch giảng viên {name}',
  'erpMyProfile.wizard.title': 'Xử lý ngoại lệ',
  'erpMyProfile.wizard.change_owner_title': 'Thay đổi nhân viên sở hữu',
  'erpMyProfile.wizard.remove_ngay_tt': 'Gỡ ngày thanh toán do Kế toán bấm sai',
  'erpHsThietBi.tenThietBi': 'Tên thiết bị',
  'erpHsThietBi.tenQuyTrinh': 'Tên quy trình',
  'erpHsThietBi.soHieu': 'Số hiệu',
  'erpHsThietBi.deleteConfirmation': 'Xóa hồ sơ {name}',
  'erpHsThietBi.cloneConfirmation': 'Sao chép thiết bị {name} vớ thông tin mới:',
  'erpHsThietBi.clearAllTplsConfirmation': 'Xóa {name} mẫu văn bản đang áp dụng trong hồ sơ này.',
  'erpHoSo.kiemDinhTitle': 'Hồ sơ Kiểm định',
  'erpHoSo.actionTitle': '{action, select,\n' +
    '    update {Cập nhật}\n' +
    '    copy {Sao chép}\n' +
    '    other {Tạo mới}\n' +
    '} Hồ sơ',
  'erpHoSo.soBienBan': 'Số biên bản',
  'erpHoSo.soTemKd': 'Số tem KĐ',
  'erpHoSo.soGct': 'Số GCT',
  'erpHoSo.mauThietBiKd': 'Mẫu thiết bị Kiểm định',
  'erpHoSo.ngayKiemDinh': 'Ngày KĐ',
  'erpHoSo.ngayKiemDinhDinhKy': 'Ngày KĐ định kỳ',
  'erpHoSo.ngayHetHan': 'Ngày hết hạn',
  'erpHoSo.tenThietBi': 'Tên thiết bị',
  'erpHoSo.tenToChuc': 'Cty đề nghị',
  'erpHoSo.tinhTp': 'Địa điểm (Tỉnh/Tp)',
  'erpHoSo.diaChiChinh': 'Địa chỉ chính',
  'erpHoSo.diaChiLapDat': 'Địa chỉ lắp đặt',
  'erpHoSo.nguoiChungKien': 'Người chứng kiến và thông qua biên bản',
  'erpHoSo.assigneeList': 'Danh sách kiểm định viên',
  'erpHoSo.deleteConfirmation': 'Xóa hồ sơ {name}',
  'erpHoSo.kqNhanXet': 'Nhận xét',
  'erpHoSo.kqLyDoRutNgan': 'Lý do rút ngắn thời hạn (nếu có)',
  'erpHoSo.kqKienNghi': 'Các kiến nghị',
  'erpHoSo.resyncFreeInputsConfirmation': 'Đồng bộ lại thông số chung & riêng cho hồ sơ {name}. Lưu ý các thông số đã nhập sẽ bị {action}.',
  'erpHoSo.changeStatusConfirmation': '{action} hồ sơ {name}',
  'erpHoSo.assigner': 'NV tạo',
  'erpHoSo.hinhThucKiemDinh': 'Hình thức KĐ',
  'contact.receiver': 'Người nhận: {name}',
  'contact.department': 'Phòng ban: {name}',
  'contact.position': 'Chức vụ: {name}',
  'contact.phone': 'Điện thoại: {name}',
  'contact.email': 'Email: {name}',
  'contact.contactWith': 'Người liên hệ: {name}',
  'contact.note': 'Ghi chú: {name}',
  'commons.note': 'Ghi chú: {name}',
  'commons.noteText': 'Ghi chú',
  'commons.refreshPage': 'Tải lại trang',
  'commons.refresh': 'Tải lại',
  'commons.reset': 'Khôi phục',
  'commons.create': 'Tạo mới',
  'commons.add': 'Thêm',
  'commons.supply': 'Bổ sung',
  'commons.update': 'Cập nhật',
  'commons.edit': 'Chỉnh sửa',
  'commons.delete': 'Xóa',
  'commons.deleteAll': 'Xóa tất cả',
  'commons.restore': 'Phục hồi',
  'commons.deleteError': 'Không thể xóa {name}',
  'commons.operations': 'Tác vụ',
  'commons.areYouSure': 'Bạn đã chắc chắn phải không?',
  'commons.cancel': 'Thoát',
  'commons.next': 'Xem tiếp',
  'commons.more': 'Xem thêm',
  'commons.ok': 'Chấp nhận',
  'commons.confirm-delete-record': 'Đang xóa nội dung bên dưới. Bạn chắc chắn phải không?',
  'commons.search': 'Tìm kiếm',
  'commons.search-placeholder': 'Nhập từ khóa tìm kiếm',
  'commons.show-more': 'Hiển thị thêm',
  'commons.save': 'Lưu',
  'commons.saveAll': 'Lưu tất cả',
  'commons.saveChanging': 'Lưu thay đổi',
  'commons.notifyUnsavedChangingForm': 'Vui lòng {name} trước khi thực hiện chức năng này!',
  'commons.back': 'Về trang trước',
  'commons.other': 'Khác',
  'commons.choose': 'Chọn',
  'commons.view': 'Xem',
  'commons.viewDetail': 'Xem chi tiết',
  'commons.detail': 'Chi tiết',
  'commons.copy': 'Sao chép',
  'commons.copyCode': 'Sao chép mã',
  'commons.copyTemplate': 'Sao chép template',
  'commons.copyToClipboard': 'Đã sao chép {name} vào clipboard',
  'commons.home': 'Trang chủ',
  'commons.defSelectOption': '---Vui lòng chọn---',
  'commons.defSearchOption': '---Gõ từ khóa tìm kiếm---',
  'commons.filter': 'Lọc',
  'commons.allOptions': 'Tất cả',
  'commons.haveErrors': 'Có lỗi!',
  'commons.systemError': 'Lỗi hệ thống!',
  'commons.400error': 'Lỗi nhập liệu, vui lòng kiểm tra lại!',
  'commons.errorDownload': 'Không tìm thấy file tải về',
  'commons.invalidJson': 'Không đúng định dạng JSON!',
  'commons.notify': 'Thông báo',
  'commons.updateSuccessful': 'Cập nhật thành công!',
  'commons.updateAndRedirectSuccessful': 'Cập nhật thành công. Đang chuyển trang, vui lòng đợi...',
  'commons.deleteSuccessful': 'Xóa thành công!',
  'commons.pleaseReview': 'Vui lòng xem lại.',
  'commons.redirecting': 'Đang chuyển trang, vui lòng đợi.',
  'commons.close': 'Đóng',
  'commons.explains': 'Chú thích!',
  'commons.building': 'Đang xây dựng...',
  'commons.noDataToDisplay': 'Không có dữ liệu để hiển thị!',
  'commons.dayMonthYear' : 'ngày {day} tháng {month} năm {year}',
  'commons.download': 'Download',
  'commons.exportName': 'Xuất {name}',
  'commons.by_name_and_time': 'Bởi {name}, lúc {atTime}',
  'commons.by_name': 'Bởi {name}',
  'commons.by_time': ' lúc {atTime}',
  'commons.submitAssign': 'Thực hiện chuyển',
  'commons.dateStart': 'Ngày bắt đầu',
  'commons.dateEnd': 'Ngày kết thúc',
  'commons.timeStart': 'Bắt đầu',
  'commons.timeEnd': 'Kết thúc',
  'commons.listTitle': 'Danh sách {name}',
  'commons.destroyThisItem': 'Xóa mục này',
  'commons.nonAssigner': 'Chưa có người nhận',
  'commons.endContent': 'Hết',
  'commons.contentText': 'Nội dung',
  'commons.detailContentText': 'Nội dung chi tiết',
  'commons.statusText': 'Trạng thái',
  'commons.statusWithNameText': 'Trạng thái {name}',
  'commons.hideShow': 'Ẩn/hiện',
  'commons.hideShowWithName': 'Ẩn/hiện {name}',
  'commons.hasNotPermission': 'Bạn không có quyền truy cập trang này!',
  'commons.nextStep': 'Tiếp tục',
  'commons.previousStep': 'Trở về',
  'commons.resetStep': 'Bắt đầu lại',
  'commons.notFoundWithConditions': 'Không tìm thấy dữ liệu thỏa điều kiện',
  'commons.totalResult': 'Thông tin tổng',
  'commons.emptyValue': '<trống>',
  'commons.sort': 'Sắp xếp',
  'commons.property': 'Thuộc tính',
  'commons.default': 'Mặc định',
  'commons.updated_at': 'Ngày cập nhật',
  'commons.updated_by': 'NV cập nhật',
  'commons.selected_rows': 'Đã chọn {number} {name}.',
  'commons.initializedValue': 'Giá trị khởi tạo',
  'commons.attached_file': 'Upload files',
  'commons.loading': 'Loading...',
  'commons.uploading': 'Uploading...',
  'commons.downloading': 'Downloading...',
  'commons.fullname': 'Họ tên',
  'commons.chucVu': 'Chức vụ',
  'commons.updatedAt': 'Cập nhật lúc',
  'commons.reloadCurrentPageAlert': 'Đang tải lại trang, vui lòng chờ!',
  'commons.asyncSelectHint': 'Nhấn phím space (khoảng cách) để tìm kiếm',
  'commons.typeToAddMore': 'Gõ để thêm mới',
  'commons.typeToAddOption': 'Thêm lựa chọn mới',
  'commons.unitName': 'Đơn vị',
  'commons.agree': 'Đồng ý',
  'commons.statusOfNew': 'Đang thực hiện',
  'commons.statusOfInProgress': 'Đang thực hiện',
  'commons.statusOfFinished': 'Hoàn tất',
  'commons.statusOfCanceled': 'Đã HỦY',
  'commons.yearText': 'Năm',
  'commons.monthText': 'Tháng',
  'commons.dayText': 'Ngày',
  'commons.copySpecialCharTitle': 'Copy ký tự đặc biệt',
  'commons.address': 'Địa chỉ',
  'commons.clickToEdit': 'Click để sửa dòng này',
  'commons.foldAll': 'Thu gọn tất cả',
  'commons.expandAll': 'Mở rộng tất cả',
  'commons.feeType': 'Loại CP',
  'commons.priceText': 'Số tiền',
  'commons.startedAtText': 'Ngày thực hiện',
  'widgets.modifyDiffPrice.title': 'Điều chỉnh nâng cao',
  'dsPagination.show': 'Hiển thị',
  'dsPagination.total': ' / Tổng {total}',
}
