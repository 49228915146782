import {createSelector} from 'reselect'
import _ from 'lodash'

import {sliceName} from './index'

const selectStore = (state) => state[sliceName]

const arrDotKey = (state, payload) => payload

const userData = createSelector(
  [selectStore, arrDotKey],
  (store, arrDotKey) => arrDotKey.map(v => _.get(store, v))
)

const userLocale = createSelector(
  [selectStore],
  (store) => store.locale
)

const selectMobile = createSelector(
  [selectStore],
  (store) => _.get(store, 'settings.layout.isMobile', false)
);

export {userData, userLocale, selectMobile}
