import {buildRequestParamRest} from './helpers'
import {REQUEST_BIG_PAGE_SIZE} from '@utils/constants'

export default {
  url: `${process.env.REACT_APP_API_URL}/erp/`,
  ...buildRequestParamRest({path: 'hang-muc-group', suffix: 'HangMucGroup'}),
  ...buildRequestParamRest({path: 'hang-muc-bao-gia', suffix: 'HangMucBaoGia'}),
  ...buildRequestParamRest({path: 'bao-gia', suffix: 'BaoGia'}),
  ...buildRequestParamRest({path: 'bao-gia-chi-tiet', suffix: 'BaoGiaChiTiet'}),
  ...buildRequestParamRest({path: 'bao-gia-chi-phi', suffix: 'BaoGiaChiPhi'}),
  ...buildRequestParamRest({path: 'ho-so-bao-gia', suffix: 'BgHoSo'}),
  updateJsonVal: {
    path: 'bao-gia-update-meta',
    method: 'PUT',
    data: {
      query: -1,
      key: null,
    }
  },
  statistic: {
    path: 'bao-gia-statistic',
    method: 'GET',
  },
  statisticOfDangThucHien: {
    path: 'bao-gia-dang-thuc-hien-statistic',
    method: 'GET',
  },
  statisticOfXuatHoaDon: {
    path: 'bao-gia-xuat-hoa-don-statistic',
    method: 'GET',
    data: {type: 'undefined'},
  },
  listDoanhThu: {
    path: 'bg-doanh-thu',
    method: 'GET',
    data: {query: -1, page: 1, limit: REQUEST_BIG_PAGE_SIZE, hasField: true, sort: {id: 'asc'}},
  },
  listDoanhThuOwner: {
    path: 'bg-doanh-thu-owner',
    method: 'GET',
    data: {query: -1, page: 1, limit: REQUEST_BIG_PAGE_SIZE, hasField: true, sort: {id: 'asc'}},
  },
  reportDoanhThu: {
    path: 'bg-doanh-thu-report',
    method: 'GET',
    data: {query: null, page: 1, limit: REQUEST_BIG_PAGE_SIZE, hasField: false},
  },
  updateDescriptionOfDoanhThu: {
    path: 'bg-doanh-thu-update-description',
    method: 'PUT',
    data: {query: null},
  },
  detailIdBaoGiaChiTiet: {
    path: 'bao-gia-chi-tiet-id',
    method: 'GET',
    data: {query: null},
  },
  updateBgChiTietSortOrder: {
    path: 'bao-gia-update-sort-order',
    method: 'PUT',
    data: {
      query: -1,
      rows: [],
    }
  },
  hoanTatBgBatch: {
    path: 'ho-so-bao-gia-hoan-tat-batch',
    method: 'POST',
    data: {ids: []},
  },
  restoreNgayThanhToan: {
    path: 'ho-so-bao-gia-restore-ngay-tt',
    method: 'POST',
    data: {so_bao_gia: []},
  },
  changeFinalDate: {
    path: 'bao-gia-change-final-date',
    method: 'PUT',
    data: {
      query: -1,
      final_date: null,
    }
  },
  cloneBaoGia: {
    path: 'bao-gia-clone',
    method: 'POST',
    data: {
      khsh_code: null,
      baogia_code: null,
      cloned_items: [],
    }
  },
}
