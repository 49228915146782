import {configureStore, getDefaultMiddleware} from '@reduxjs/toolkit'
import {applyMiddleware, compose, combineReducers} from 'redux'
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import createSagaMiddleware from 'redux-saga'

import dataSource from './dataSource'
import user from './user'
import rootSaga from './rootSaga'
import {REDUX_PERSIST_KEY} from '../utility/constants'

// root reducer
const rootReducer = combineReducers({user, dataSource})

// redux-persist
const persistConfig = {
  key: REDUX_PERSIST_KEY,
  storage,
  serialize: true,
  whitelist: ['user'],
}
const persistedReducer = persistReducer(persistConfig, rootReducer)

// redux-saga
const sagaMiddleware = createSagaMiddleware()
const middlewares = [
  ...getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
    }
  }),
  sagaMiddleware,
]

// configure dev tool
const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV === "development" ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose : false,
  middleware: middlewares,
})

// run redux-saga
sagaMiddleware.run(rootSaga)

export default store

// redux-persist / persistor
export const persistor = persistStore(store)
