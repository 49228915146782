import {createSlice, createAction} from '@reduxjs/toolkit'
import {REQUEST_PAGE_SIZE} from "../../utility/constants"
import _ from 'lodash'

import {entityAdapter} from './selectors'

const defOption = {
  isLoading: false,
  isLoadingSet: {},
  rowSelected: {},
  fields: [],
  errors: {},
  total: 0,
  page: 1,
  limit: REQUEST_PAGE_SIZE,
}
const defSourceId = 'default'
const sliceName = 'dataSource'

const slice = createSlice({
  name: sliceName,
  initialState: {
    default: entityAdapter ? entityAdapter.getInitialState({...defOption}) : {...defOption}
  },
  reducers: {
    initSource(state, {payload}) {
      const {sourceId, ...others} = payload

      state[sourceId] = entityAdapter.getInitialState({...defOption, ...others})
    },
    destroySource(state, {payload}) {
      const {sourceId = defSourceId} = payload

      if (typeof sourceId === 'string' && typeof state[sourceId] !== 'undefined') {
        delete state[sourceId]
        return
      }

      if (Array.isArray(sourceId)) {
        sourceId.forEach(v => {
          if (typeof state[v] !== 'undefined') {
            delete state[v]
          }
        })
      }
    },
    pushData(state, {payload}) {
      const {sourceId = defSourceId, data, isReset = false} = payload

      if (!(data && (sourceId in state))) {
        return
      }

      if (isReset) {
        entityAdapter.setAll(state[sourceId], data)
        return
      }

      if (Array.isArray(data)) {
        entityAdapter.addMany(state[sourceId], data)
      } else {
        entityAdapter.addOne(state[sourceId], data)
      }
    },
    updateData(state, {payload}) {
      const {sourceId = defSourceId, data} = payload

      if (!data || data.length === 0 || !(sourceId in state)) {
        return
      }

      if (Array.isArray(data)) {
        entityAdapter.upsertMany(state[sourceId], data)
      } else {
        entityAdapter.upsertOne(state[sourceId], data)
      }
    },
    updateDataPath(state, {payload}) {
      const {sourceId = defSourceId, data} = payload
      const arrData = Array.isArray(data) ? data : [data]

      arrData.forEach(o => {
        const {entityId, path, value} = o

        if (!(path && entityId)) {
          return
        }

        const row = _.cloneDeep(entityAdapter.getSelectors().selectById(state[sourceId], entityId))

        if (!row) {
          return
        }

        _.set(row, path, value)

        entityAdapter.upsertOne(state[sourceId], row)
      })
    },
    popData(state, {payload}) {
      const {sourceId = defSourceId} = payload

      if (state[sourceId].ids.length === 0) {
        return
      }

      const entityId = state[sourceId].ids.pop()

      entityAdapter.removeOne(state[sourceId], entityId)
    },
    pullData(state, {payload}) {
      const {sourceId = defSourceId, entityId} = payload

      if (state[sourceId].ids.length === 0 || !entityId) {
        return
      }

      if (Array.isArray(entityId)) {
        entityAdapter.removeMany(state[sourceId], entityId)
      } else {
        entityAdapter.removeOne(state[sourceId], entityId)
      }
    },
    removeDataPath(state, {payload}) {
      const {sourceId = defSourceId, data} = payload
      const arrData = Array.isArray(data) ? data : [data]

      arrData.forEach(o => {
        const {entityId, path} = o

        if (!(path && entityId)) {
          return
        }

        const row = entityAdapter.getSelectors().selectById(state[sourceId], entityId)

        entityAdapter.upsertOne(state[sourceId], _.omit(row, path))
      })
    },
    setOption(state, {payload}) {
      const {sourceId = defSourceId, data} = payload

      if (typeof state[sourceId] === 'undefined') {
        return
      }

      const repeater = Array.isArray(data) ? [data] : Object.entries(data)

      repeater.forEach(arr => {
        if (arr.length !== 2) {
          return
        }

        _.set(state[sourceId], arr[0], arr[1])
      })
    },
    removeOption(state, {payload}) {
      const {sourceId = defSourceId, data} = payload

      if (typeof state[sourceId] === 'undefined') {
        return
      }

      const repeater = Array.isArray(data) ? data : [data]

      repeater.forEach(v => {
        _.unset(state[sourceId], v)
      })
    },
  },
  // .reducers
  extraReducers: {
  }
})

export const {
  initSource,
  destroySource,
  pushData,
  updateData,
  updateDataPath,
  popData,
  pullData,
  removeDataPath,
  setOption,
  removeOption,
} = slice.actions
export const syncActions = createAction(`${sliceName}/syncActions`)

export {sliceName, defSourceId, defOption}

export default slice.reducer
