// ** Third party components
import _ from 'lodash'

// ** Built-in components
import {genUniqIdBasedOnTime}  from '@utils/basicHelper'

export const isBlobResponse = (response) => _.get(response, 'config.responseType') === 'blob'

export const parseDownloadResponse = (response) => {
  const contentType = response.headers['content-type']
  let ext = null

  switch (contentType) {
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      ext = 'xlsx'
      break
    case 'application/pdf':
      ext = 'pdf'
      break
    default:
      ext = 'txt'
  }

  return {
    content: response.data,
    fileName: `${genUniqIdBasedOnTime()}.${ext}`
  }
}
