export const REQUEST_PAGE_SIZE = 25;
export const REQUEST_LARGE_PAGE_SIZE = 100;
export const REQUEST_BIG_PAGE_SIZE = 1000;
export const REDUX_PERSIST_KEY = process.env.REACT_APP_PERSIST_KEY;
export const REDUX_PERSIST_FULL_KEY = `persist:${REDUX_PERSIST_KEY}`;
export const SAFE_STATUS_CODES = [200, 201];
export const TOAST_TIMEOUT = 5000;
export const INPUT_TIMEOUT = 300 // miliseconds;
export const GCT_UPDATE_FEE_DEADLINE = 5 // 5th of month;
export const REDIRECT_TIMEOUT = 1500; // miliseconds
export const RELOAD_PAGE_TIMEOUT = 3000; // miliseconds
export const REFETCH_TIMEOUT = 3000; // miliseconds

export const GIAM_DOC_PERM_KEY = 'iam:administrator:quyen-giam-doc';
export const PHO_GIAM_DOC_PERM_KEY = 'iam:administrator:pho-giam-doc';
export const NHAN_SO_HUU_PERM_KEY = 'erp:kinh-doanh:nhan-so-huu';
export const QUAN_LY_BG_PERM_KEY = 'erp:kinh-doanh:quan-ly-bg';
export const QUAN_LY_GCT_PERM_KEY = 'erp:kinh-doanh:quan-ly-gct';
export const QUAN_LY_NHAN_SU_PERM_KEY = 'erp:nhan-su:quan-ly-nhan-su';
export const QUAN_LY_DOANH_THU_PERM_KEY = 'erp:ke-toan:quan-ly-doanh-thu';
export const QUAN_LY_HE_THONG_PERM_KEY = 'erp:he-thong:chinh-sua-hang-muc-bao-gia';
export const TRUONG_NHOM_NVKD_PERM_KEY = 'erp:kinh-doanh:truong-nhom-nvkd';
export const QUAN_LY_THIET_BI_KIEM_DINH_PERM_KEY = 'erp:he-thong:quan-ly-thiet-bi-kiem-dinh';

export const highlightClass = 'font-weight-bold text-warning';
export const dangerTextClass = 'font-weight-bold text-danger';

export const cacDichVu = [
  {id: 'huan-luyen', value: 'huan-luyen', label: 'Huấn luyện'},
  {id: 'dao-tao', value: 'dao-tao', label: 'Đào tạo'},
  {id: 'quan-trac', value: 'quan-trac', label: 'Quan trắc'},
  {id: 'kiem-dinh', value: 'kiem-dinh', label: 'Kiểm định'},
  {id: 'giam-sat-moi-truong', value: 'giam-sat-moi-truong', label: 'Giám sát môi trường'},
  {id: 'hieu-chuan-do-luong', value: 'hieu-chuan-do-luong', label: 'Hiệu chuẩn đo lường'},
];

export const DV_HUAN_LUYEN = 'huan-luyen';
export const DV_DAO_TAO = 'dao-tao';
export const DV_QUAN_TRAC = 'quan-trac';
export const DV_KIEM_DINH = 'kiem-dinh';
export const DV_GIAM_SAT_MOI_TRUONG = 'giam-sat-moi-truong';
export const DV_HIEU_CHUAN = 'hieu-chuan-do-luong';

export const cacRoleQuanLyGct = [
  {id: 'nvkd', value: 'nvkd', label: 'NVKD', alias: 'NVKD'},
  {id: 'truong-phu-trach', value: 'truong-phu-trach', label: 'Trưởng phụ trách', alias: 'TPT'},
  {id: 'truong-nhom', value: 'truong-nhom', label: 'Trưởng nhóm', alias: 'TN'},
  {id: 'van-thu', value: 'van-thu', label: 'Văn thư', alias: 'VT'},
];

export const hinhThucKiemDinhOptions = [
  { value: 'undefined', label: 'Chưa xác định' },
  { value: 'lan-dau', label: 'Lần đầu' },
  { value: 'dinh-ky', label: 'Định kỳ' },
  { value: 'bat-thuong', label: 'Bất thường' },
];

export const GCT_HOAN_TAT_STATUS_FLAG_IN_NUMBER = 836;

export const EMAIL_DOMAIN = '@antoanmiennam.com';
