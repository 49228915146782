export default {
  loginFailed: "Login failed!",
  "users.title": "USERS",
  "users.create": "Create new user",
  "users.update": "Update user: {name}",
  "users.delete": "Delete user: {name} ({email})",
  "commons.refresh": "Refresh",
  "commons.reset": "Reset",
  "commons.create": "Create",
  "commons.update": "Update",
  "commons.edit": "Edit",
  "commons.delete": "Delete",
  "commons.operations": "Operations",
  "commons.areYouSure": "Are you sure?",
  "commons.cancel": "Cancel",
  "commons.next": "Next",
  "commons.ok": "Ok",
  "commons.confirm-delete-record": "Delete below record. Are you sure?",
  "commons.search": "Search",
  "commons.search-placeholder": "Type to search",
  "commons.show-more": "Show more",
  "commons.save": "Save",
  "commons.back": "Back",
  "ds.pager.show-info": "Showing {number}/{total}",
}
