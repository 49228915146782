import {call, put, fork, select} from 'redux-saga/effects'

import {injectTokenHeader, parseDsFields, processStackActions} from './helper'
import {logout} from '../user'
import request from '../../utility/request'
import {setOption, pushData} from '../dataSource'
import {getOption} from '../dataSource/selectors'

function *processListRequest(params) {
  const {sourceId, response, isPushed = false} = params

  yield put(pushData({ sourceId, isReset: !isPushed, data: response.rows || response.data }));

  const payload = {
    sourceId,
    data: {
      isLoading: false,
      page: response.page,
      limit: response.limit,
      options: {},
    }
  }

  if ('options' in response) {
    payload.data.options = response.options
  }

  if (response.fields && response.fields.length !== 0) {
    payload.data = {
      ...payload.data,
      fields: response.fields || [],
      ...parseDsFields(response.fields || []),
    }
  }

  if (response.page === 1) {
    payload.data.total = response.total
  }

  yield put(setOption(payload))
}

function *processSuccessResponse(params) {
  const {sourceId, response, exOptions} = params

  yield put(setOption({
    sourceId,
    data: {
      resData: response,
      isLoading: false,
      ...exOptions,
    }
  }))
}

function* callApi(payload) {
  const {sourceId, isListRequest = true, beforeActions = [], afterActions = []} = payload
  yield put(setOption({sourceId, data: ['isLoading', true]}))

  if (beforeActions.length !== 0) {
    yield call(processStackActions, {actions: beforeActions})
  }

  try {
    const [reqParam] = yield select(state => getOption(state, {sourceId, data: ['reqParam']}))
    const response = yield call(request, reqParam)

    if (isListRequest) {
      yield call(processListRequest, {...payload, response})
    } else {
      yield call(processSuccessResponse, {...payload, response})
    }

    if (afterActions.length !== 0) {
      yield call(processStackActions, {actions: afterActions})
    }
  } catch (error) {
    // Unauthorized
    if (error === 'unauthenticated') {
      yield put(logout())
    }
  }
}

function* watching(action) {
  // inject Bearer token
  yield call(injectTokenHeader)

  yield fork(callApi, action.payload)
}

export default watching
