export default {
  url: `${process.env.REACT_APP_APIV1_URL}/core/`,
  uploadFile: {
    path: 'file-meta/upload',
    method: 'POST',
    data: { file: null },
  },
  downloadFile: {
    path: 'file-meta/download',
    method: 'GET',
    data: { query: -1 },
  },
  downloadExportedFile: {
    path: 'download-exported-file',
    method: 'GET',
    data: { fileName: 'my-file' },
  },
  searchTinhTp: {
    path: 'search-tinh-tp',
    method: 'GET',
    data: {},
  },
}
