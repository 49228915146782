import { Ability } from '@casl/ability'
import {REDUX_PERSIST_FULL_KEY} from '@utils/constants'

//  Read ability from localStorage
// * Handles auto fetching previous abilities if already logged in user
// ? You can update this if you store user abilities to more secure place
// ! Anyone can update localStorage so be careful and please update this
const persistStorage = JSON.parse(localStorage.getItem(REDUX_PERSIST_FULL_KEY))

let permissions = [{action: 'read', subject: 'iam:administrator:logined-user'}]

if (persistStorage && persistStorage.user) {
  const userData = JSON.parse(persistStorage.user)

  permissions = userData.ability || [...permissions]
}

export default new Ability(permissions)
