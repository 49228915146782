import {buildRequestParamRest} from './helpers'

export default {
  url: `${process.env.REACT_APP_API_URL}/`,
  ...buildRequestParamRest({path: 'iam/user'}),
  requestToken: {
    path: 'oauth/token',
    method: 'POST',
    data: {
      grant_type: 'password',
      client_id: process.env.REACT_APP_API_CLIENT_ID,
      client_secret: process.env.REACT_APP_API_CLIENT_SECRET,
      scope: '*'
    }
  },
  whoami: {
    path: 'iam/user/whoami',
    method: 'GET',
  },
  permissionIds: {
    path: 'iam/user/permission-ids',
    method: 'GET',
    data: {
      query: -1,
    }
  },
  attachPermissions: {
    path: 'iam/user/permissions-attach',
    method: 'PUT',
    data: {
      query: -1,
      permissions: [],
    }
  },
}
