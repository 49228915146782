import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import _ from 'lodash';
import mobile from 'is-mobile';

import { EMAIL_DOMAIN } from '@utils/constants';
import api from '../../services/api'
import request from '../../utility/request'

const defState = {
  userInfo: {},
  oauth: {},
  isLoggined: false,
  permissions: [],
  ability: [],
  retryTimes: 0,
  locale: 'vi',
  settings: {
    navbar: {
      suggestions: [],
      bookmarks: [],
      query: ''
    },
    layout: {
      isRTL: false,
      menuCollapsed: true,
      menuHidden: true,
      contentWidth: 'full',
      isMobile: mobile(),
    },
  },
}
const sliceName = 'user'

const login = createAsyncThunk(
  `${sliceName}/login`,
  async (payload, {rejectWithValue}) => {
    if (payload.username.indexOf('@') === -1) {
      payload.username += EMAIL_DOMAIN;
    }

    const reqParam = api('user', 'requestToken', payload)
    const res = await request(reqParam)

    if (!res['access_token']) {
      return rejectWithValue({payload: 'unauthorized'})
    }

    return res
  }
)

const slice = createSlice({
  name: sliceName,
  initialState: {...defState},
  reducers: {
    logout(state) {
      Object.keys(defState).forEach((k) => {
        state[k] = defState[k]
      })

      // remove token from localStorage
      localStorage.removeItem(`${process.env.REACT_APP_PERSIST_KEY}:jwt`);
    },
    resetRetryTimes(state) {
      state.retryTimes = 0
    },
    setUserSetting(state, action) {
      const { data } = action.payload

      const repeater = Array.isArray(data) ? [data] : Object.entries(data)

      repeater.forEach((arr) => {
        _.set(state.settings, arr[0], arr[1])
      })
    },
    setOption(state, {payload}) {
      const {data} = payload

      const repeater = Array.isArray(data) ? [data] : Object.entries(data)

      repeater.forEach(arr => {
        _.set(state, arr[0], arr[1])
      })
    },
  },
  // .reducers
  extraReducers: {
    [login.fulfilled]: (state, action) => {
      const { payload } = action

      state.isLoggined = true
      state.oauth = { ...payload }
      state.retryTimes = 0

      // write token into localStorage
      localStorage.setItem(`${process.env.REACT_APP_PERSIST_KEY}:jwt`, JSON.stringify(payload));
    },
    [login.rejected]: (state, action) => {
      state.isLoggined = false
      state.retryTimes++
    },
  },
})

export const {logout, resetRetryTimes, setUserSetting, setOption} = slice.actions
export {sliceName, login}

export default slice.reducer
