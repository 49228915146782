import _ from 'lodash'

import user from './user'
import iamPermission from './iamPermission'

import ciCore from './ciCore'

import erpNhanVien from './erpNhanVien'
import erpBaoGia from './erpBaoGia'
import erpGiayCongTac from './erpGiayCongTac'
import erpKhachHang from './erpKhachHang'
import erpKinhDoanh from './erpKinhDoanh'
import erpHoSo from './erpHoSo'
import apiCore from './apiCore'
import apiv1Core from './apiv1Core'

const configs = {
  user,
  iamPermission,
  apiCore,
  apiv1Core,
  ciCore,
  erpNhanVien,
  erpBaoGia,
  erpGiayCongTac,
  erpKhachHang,
  erpKinhDoanh,
  erpHoSo,
}

export default (key, action, data = {}) => {
  let params

  try {
    params = {
      url: configs[key].url,
      method: configs[key][action].method,
      data: {
        ...configs[key][action].data,
        ...data
      }
    }
  } catch (e) {
    throw `Invalid API params: [${key}, ${action}]`
  }

  if (_.has(configs, [key, action, 'path'].join('.'))) {
    params.url += configs[key][action].path
  }

  if ('query' in data) {
    params.url += `/${data.query}`
    delete params.data.query
  }

  return _.cloneDeep(params)
}
