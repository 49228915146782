import {all, takeEvery} from 'redux-saga/effects'

// ** Import actions
import {syncActions} from './index'

// ** Saga watching
import syncActionsWatching from './syncActionsWatching'

export default function* rootSaga() {
  yield all([takeEvery(syncActions.toString(), syncActionsWatching)])
}
